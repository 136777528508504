import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs/internal/Observable';
import { UserDocument } from '../models/UserDocument';

@Injectable()
export class WebMedUserService {

  apiUrl: string;
  webmedUserUrl: string = '/api/webmeduser';
  headers: HttpHeaders | undefined;

  constructor(private http: HttpClient, oidcSecurityService: OidcSecurityService,  configService: ConfigurationService,) {
    oidcSecurityService.getAccessToken().subscribe((token) => {
      this.headers = new HttpHeaders({
        Authorization: 'Bearer ' + token,
      });
    }); 
    this.apiUrl =configService.config.apiUrl
  }
  getMyDocuments(): Observable<UserDocument[]> {
    return this.http.get<UserDocument[]>(`${this.apiUrl}${this.webmedUserUrl}/documents`, { headers: this.headers });
  }
  

  // service.ts
  downloadFile(uuid: string) {
    return this.http.get<Blob>(`${this.apiUrl}${this.webmedUserUrl}/documents/${uuid}`, { observe: 'response', responseType: 'blob' as 'json', headers: this.headers });    
  }
}
