import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class TimeSlotService {
  apiUrl: string;
  timeSlotsRoute: string = "/api/TimeSlots";
  headers: HttpHeaders | undefined;

  constructor(private http: HttpClient, configService: ConfigurationService, oidcSecurityService: OidcSecurityService) {
    oidcSecurityService.getAccessToken().subscribe((token) => {
      this.headers = new HttpHeaders({
        Authorization: 'Bearer ' + token,
      });
    }); 
    console.log(this.headers)
    this.apiUrl = configService.config.apiUrl
  }

  getTimeSlotDays() {
    return this.http.get(`${this.apiUrl}${this.timeSlotsRoute}/days`,{headers : this.headers})
  }

}
