<div [ngClass]="setClasses()" [class.disableBox]="!day.timeSlotGroups">
    <div (click)="showTimeSlots = !showTimeSlots" class="box">
      <h3>{{dayName}}</h3>
      <h3 class="date">{{day.date | date: 'dd/MM/yyyy'}}</h3>
    </div>
    <div class="appointments">
      <ng-template [ngIf]="showTimeSlots && day.timeSlotGroups">
        <div *ngFor="let item of day.timeSlotGroups; let i = index" class="appointment-box">
          <label for="radio1">{{item.startTime}} - {{item.endTime}}</label>
          <input  type="radio" (change)="selectedTime(item.startTime)" class="select-appointment"
            [id]="'appointment'" [name]="'appointment'">
        </div>
        <button (click)="bookTime(day.date)" class="slot booked"><span
            class="book-span">Lagre tid</span></button>
      </ng-template>
    </div>
