import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { ConfigurationService } from './configuration.service';
import { DocumentList } from '../models/DocumentList';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class UpgradeService {
  apiUrl: string;
  upgradeRoute = "/api/upgrade";
  headers: HttpHeaders | undefined;

  constructor(private http: HttpClient, configService: ConfigurationService, oidcSecurityService: OidcSecurityService) {
    oidcSecurityService.getAccessToken().subscribe((token) => {
      this.headers = new HttpHeaders({
        Authorization: 'Bearer ' + token,
      });
    }); 
    this.apiUrl = configService.config.apiUrl
  }

  getUpgradeDocuments(upgradeId: string) {
    return this.http.get<DocumentList>(`${this.apiUrl}${this.upgradeRoute}/${upgradeId}/documentList`, {headers : this.headers});
    }

  downloadUpgradeFile(upgradeId: string) {
      let url = `${this.apiUrl}${this.upgradeRoute}/${upgradeId}/file`;
      return this.http
        .request(
          new HttpRequest("GET", url, {
            headers : this.headers,
            reportProgress: true,
            responseType: "blob",
          })
        )
    }

}


