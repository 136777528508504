import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';

declare var getAuthURL: any;

@NgModule({
    imports: [AuthModule.forRoot({
      config: {
        authority: getAuthURL(),
        redirectUrl: window.location.origin + "/callback-oidc",
        postLogoutRedirectUri: window.location.origin,
        clientId: 'status-page',
        scope: 'openid statussite offline_access',
        responseType: 'code',
        autoUserInfo: true,
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30
      }
      })],
    exports: [AuthModule],
})
export class AuthConfigModule {}

