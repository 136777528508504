<p-card *ngIf="(documents | async)?.length" header="Mine Dokumenter" [style]="{
    border: '2px solid black',
    borderRadius: '15px',
    width: '500px',
    color: '#393939',
    'font-family': 'SuisseRegular',
    'margin-bottom': '5px'
}">
  <table>
    <tr>
      <th>Filenavn:</th>
      <th>Dato:</th>
    </tr>
    <tr *ngFor="let document of documents | async;">
      <td><a [routerLink]="[]" (click)="downloadFile(document.id,document.fileName+document.extension, document.mimeType)">{{document.fileName}}{{document.extension}}</a></td>
      <td>{{document.created | date:'short'}}</td>
    </tr>
  </table>
</p-card>
<p-card *ngFor="let server of servers | async;" header="Kunde: {{server.customer?.name}}"
        [style]="{
        border: '2px solid black',
        borderRadius: '15px',
        width: '500px',
        color: '#393939',
        'font-family': 'SuisseRegular',
        'margin-bottom': '5px'
    }">
  <table>
    <tr>
      <td>Kontor:</td>
      <td>{{server.softwareName}}</td>
      <td>Versjon:</td>
      <td>{{server.softwareVersion}}</td>
    </tr>
    <tr>
      <td>DNS:</td>
      <td>{{server.dnsName}}</td>
      <td>Sist sett:</td>
      <td>{{server.lastSeen | date:'short'}}</td>
    </tr>
    <tr>
      <td colspan="2"><a href="https://{{server.dnsName.replace('kunde','public')}}/clickonce/publish.htm">Klient Nedlasting</a></td>
      <td>Status:</td>
      <td>{{server.status}}</td>
    </tr>
    <tr>
      <td colspan="2"><a href="https://{{server.dnsName.replace('kunde','public')}}/clickonce/TNW.Desktop.WPF.application">Start WebMed</a></td>
    </tr>
  </table>
</p-card>

<div>For å laste ned ny VPN profil <a href="https://vpn.webmed.no">vpn.webmed.no</a></div>

