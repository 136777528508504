
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class AppointmentService {
  apiUrl: string;
  appointmentRoute: string = '/api/Appointments';
  headers: HttpHeaders | undefined;

  constructor(private http: HttpClient, configService: ConfigurationService, oidcSecurityService: OidcSecurityService) {
    oidcSecurityService.getAccessToken().subscribe((token) => {
      this.headers = new HttpHeaders({
        Authorization: 'Bearer ' + token,
      });
    }); 
    this.apiUrl =configService.config.apiUrl
  }

  getAppointmentByAppointmentId(appointmentId: string) {
  return this.http.get(`${this.apiUrl}${this.appointmentRoute}/${appointmentId}`, {headers : this.headers});
  }

  setAppointmentTime(data: any) {
    return this.http.post(`${this.apiUrl}${this.appointmentRoute}/time`, data, {
      headers: this.headers,
    });
  }
}
