
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppointmentService } from 'src/app/services/appointment.service';
import { getDay, parseISO } from 'date-fns'

export interface BookingDto {
  day: any,
  StartTime: any,
  AppointmentId: string,
}

@Component({
  selector: 'app-booking-days',
  templateUrl: './booking-days.component.html',
  styleUrls: ['./booking-days.component.css']
})
export class BookingDaysComponent implements OnInit, OnChanges { //

  @Input() day: any;
  @Input() upgradeId: string | undefined;
  items: any[] = [];
  hours: any;
  dayName!: string;
  selected: boolean = false;
  showTimeSlots = true;
  selectedTimeSlotId = undefined;
  selectedTimeSlotTime: any;
  appointmentId: string = "";


  constructor(private appointmentService: AppointmentService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let appointmentQuery = this.route.snapshot.paramMap.get('appointmentId');
    this.appointmentId += appointmentQuery;

  }

  setClasses() {
    let classes = {
      day: true,
      selected: this.selected
    }
    return classes;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.day.currentValue)
      this.day = changes.day.currentValue;
      this.dayName = this.getDayName(this.day);

  }

  get12HourTime(data: any) {
    if (data > 12)
      return ' PM';
    return ' AM';
  }



  getDayName(date:any){
    let day: string;
    switch (getDay(parseISO(date.date))) {
      case 0:
        day = "Søndag";
        break;
      case 1:
        day = "Mandag";
        break;
      case 2:
         day = "Tirsdag";
        break;
      case 3:
        day = "Onsdag";
        break;
      case 4:
        day = "Torsdag";
        break;
      case 5:
        day = "Fredag";
        break;
      case 6:
        day = "Lørdag";
    }
    return day;
  }


  selectedTime(data: any) {
    this.selectedTimeSlotTime = data;
    console.log(data)
  }




  bookTime(day: any) {

    if (!this.selectedTimeSlotTime)
      return;
    const booking: BookingDto = {
      day: day,
      StartTime: this.selectedTimeSlotTime,
      AppointmentId: this.appointmentId

    };

    this.appointmentService.setAppointmentTime(booking).subscribe(result => {
      window.location.href = window.location.href;
    })

  }


}
