import { Component } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { Server } from '../models/Server';
import { ServersService } from '../services/servers.service';
import { WebMedUserService } from '../services/webmeduser.service';
import { UserDocument } from '../models/UserDocument';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css']
})
export class HomeComponent {
  servers!: Observable<Server[]>;
  documents!: Observable<UserDocument[]>;
  baseUrl: string;
  constructor(private serversService: ServersService, private userService: WebMedUserService) {
    this.baseUrl = this.userService.apiUrl + this.userService.webmedUserUrl;
  }
  ngOnInit(): void {
    this.servers = this.serversService.getMyServers();
    this.documents = this.userService.getMyDocuments();
    
  }

  downloadFile(uuid: any, fileName: string, mimeType: string) {
    this.userService.downloadFile(uuid)
      .subscribe(
        (response: HttpResponse<Blob>) => {
          let filename: string = fileName;
          let binaryData = [];
          binaryData.push(response.body ?? new Blob());
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: mimeType }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      )
  }
}
