<div *ngIf="!bookingData"> Loading...</div>
<div class="wrapper" *ngIf="bookingData && upgradeDocuments">
  <header>
    <div id="leftGridElm">
      <img src="../../../assets/webmed_logo2.svg" alt="" id="webmedLogo">
    </div>
    <div id="centerGridElm">
      <div id="upgradeBox">
        <h1 *ngIf="bookingData" id="header-title">Systemoppdatering - Versjon: {{bookingData.upgradeVersion}} </h1>
        <div id="upgrade-selector-wrapper">
          <label id="earlier-label" for="upgrade-Selecor">Tidligere oppdateringer: </label>
          <select (change)="getAppointmentFromList($event)" name="upgrade-Selecor" id="upgrade-Selecor">
            <option [value]="undefined" selected> Velg versjon</option>
            <option *ngFor="let item of upgrades" [value]="item.key">{{item.value}}</option>
          </select>
        </div>
      </div>
    </div>
    <div id="rightGridElm">
      <div id="loginBox">
      <button id="logOutBtn" (click)="logout()">Logg ut</button>
      <i class="pi pi-user" id="profilIcon">
        <span id="iconText">{{userData.name}}</span>
      </i>
    </div>
    </div>
  </header>

  <div>
    <div id="upgradeInfo">
      <h4 style="color: red"> Informasjon:</h4>
      <h4 id="information" *ngIf="bookingData" style="max-width:1000px ; white-space: pre-line">
        {{bookingData.description}}</h4>
      <h4 style="color: red;">Oppdateringsnotat:</h4>
      <button *ngFor="let doc of upgradeDocuments.documentDictionary | keyvalue" class="releaseDocument"
        (click)="downloadDocument(doc)">{{doc.value}}</button>
    </div>
    <app-booking-container *ngIf="inviteMode" [upgradeId]="upgradeId">
    </app-booking-container>

    <app-booked-appointment *ngIf="!inviteMode" [bookingData]="bookingData">
    </app-booked-appointment>
  </div>
</div>
