import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { Server } from '../models/Server';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class ServersService {

  apiUrl: string;
  serversUrl: string = '/servers';
  headers: HttpHeaders | undefined;

  constructor(private http: HttpClient, oidcSecurityService: OidcSecurityService, configService: ConfigurationService,) {
    oidcSecurityService.getAccessToken().subscribe((token) => {
      this.headers = new HttpHeaders({
        Authorization: 'Bearer ' + token,
      });
    }); 
    this.apiUrl = configService.config.publicApiUrl
  }
  getMyServers(): Observable<Server[]> {
    return this.http.get<Server[]>(`${this.apiUrl}${this.serversUrl}/mine`, { headers: this.headers });
  }
}
