import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class CustomerService {

  apiUrl: string;
  customerUrl: string = '/api/customer';
  headers: HttpHeaders | undefined;

  constructor(private http: HttpClient, oidcSecurityService: OidcSecurityService,  configService: ConfigurationService,) {
    oidcSecurityService.getAccessToken().subscribe((token) => {
      this.headers = new HttpHeaders({
        Authorization: 'Bearer ' + token,
      });
    }); 
    this.apiUrl =configService.config.apiUrl
  }
  getAllAppointmentsByCustomerId(customerId: string) {
    return this.http.get(`${this.apiUrl}${this.customerUrl}/${customerId}/appointments`, {headers : this.headers});
    }
}
