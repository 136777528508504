import { CustomerService } from 'src/app/services/customer.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UpgradeService } from 'src/app/services/upgrade.service';
import { BookingData } from 'src/app/models/BookingData';
import { DocumentList } from 'src/app/models/DocumentList';
import { KeyValue } from '@angular/common';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-booking-main',
  templateUrl: './booking-main.component.html',
  styleUrls: ['./booking-main.component.css'],
})
export class BookingMainComponent implements OnInit {
  appointmentId: string = '';
  upgradeId!: string;
  inviteMode: boolean = false;
  upgradeFileName!: string;
  upgradeDocuments!: DocumentList;
  upgrades!: [{ key: string; value: string }];
  bookingData!: BookingData;
  userData!: any;

  constructor(
    private upgradeService: UpgradeService,
    private customerService: CustomerService,
    private appointmentsService: AppointmentService,
    private route: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit(): void {
    this.appointmentId += this.route.snapshot.paramMap.get('appointmentId');
    this.userData = this.oidcSecurityService.getUserData();
    this.appointmentsService
      .getAppointmentByAppointmentId(this.appointmentId)
      .subscribe((result: any) => {
        this.setAppointmentData(result);
      });
  }

  getAppointmentFromList(input: any) {
    this.appointmentsService
      .getAppointmentByAppointmentId(input.target.value)
      .subscribe((result: any) => {
        this.setAppointmentData(result);
      });
  }

  getAllAppointmentsByCustomer() {
    this.customerService
      .getAllAppointmentsByCustomerId(this.bookingData.customerId)
      .subscribe((result: any) => {
        this.upgrades = result;
      });
  }

  setAppointmentData(result: BookingData) {
    this.bookingData = result;
    this.inviteMode = this.bookingData.inviteMode;
    this.upgradeId = this.bookingData.upgradeId;
    this.addFileNames();
    this.getAllAppointmentsByCustomer();
  }

  addFileNames() {
    this.upgradeService
      .getUpgradeDocuments(this.upgradeId)
      .subscribe((result: DocumentList) => {
        this.upgradeDocuments = result;
      });
  }

  async downloadDocument(doc: KeyValue<string, string>) {
    this.upgradeService.downloadUpgradeFile(doc.key).subscribe((data: any) => {
      if (data.type != 4 || !data.body) {
        return;
      }
      const blob = new Blob([data.body as Blob], {
        type: data.headers.get('Content-type'),
      });
      const dataURL = window.URL.createObjectURL(blob);
     /* if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }*/

      const link = document.createElement('a');
      link.href = dataURL;

      link.download = doc.value;
      link.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(dataURL);
      }, 100);
    });
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  // FOR SOME REASON NOT WORKING
  // getFileNameFromHttpResponse(httpResponse: HttpResponse<any>) {
  //   let contentDispositionHeader = httpResponse.headers.get(
  //     'Content-Disposition'
  //   );

  //   console.log("httpResponse", httpResponse)
  //   console.log("contentDispositionHeader", contentDispositionHeader);
  //   let result =
  //     (contentDispositionHeader &&
  //       contentDispositionHeader.split(';')[1].trim().split('=')[1]) ||
  //     '';
  //   return result.replace(/"/g, '');
  // }
}
